var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[(!_vm.isLandingPage)?_c('v-app-bar',{staticClass:"header-bar",class:{ 'pt-3 pb-1': _vm.isWebView },attrs:{"app":"","dark":"","id":"header-bar","color":_vm.isLocal
                ? 'warning'
                : _vm.$vuetify.theme.dark
                ? 'grey darken-4'
                : 'primary',"height":_vm.systemBarHeight}},[_c('v-app-bar-nav-icon',{staticClass:"float-left",on:{"click":_vm.toggleNavigationDrawer}}),_c('div',{style:({
                width: _vm.$vuetify.breakpoint.lgAndUp ? '100px' : '50px',
            })}),_c('div',{staticClass:"mx-auto"},[_c('router-link',{attrs:{"exact":"","to":{ name: _vm.$constants.ROUTE_NAMES.HOME }}},[_c('logo')],1)],1),(_vm.showUpdateButton)?_c('update-app-button'):_c('push-notifications-button'),_c('v-progress-linear',{attrs:{"active":_vm.loading,"indeterminate":_vm.loading,"absolute":"","bottom":"","color":"primary"}})],1):_vm._e(),(!_vm.isLandingPage)?_c('v-navigation-drawer',{attrs:{"app":"","floating":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [(_vm.user)?_c('v-sheet',{staticClass:"pa-4",attrs:{"color":_vm.$vuetify.theme.dark
                        ? 'gray darken-2'
                        : _vm.isWebView
                        ? '#1e1e1e'
                        : 'brown lighten-5'}},[_c('v-row',{class:{ 'mt-6': _vm.isWebView }},[_c('v-avatar',{staticClass:"mb-4 mx-auto mt-1",attrs:{"color":"grey darken-1","size":"64"}},[(_vm.user.photoURL)?_c('v-img',{attrs:{"src":_vm.user.photoURL}}):_c('span',{staticClass:"text-uppercase",class:{ 'white--text': _vm.isWebView }},[_vm._v(" "+_vm._s(_vm.user.displayName ? _vm.user.displayName.substr(0, 2) : _vm.user.email.substr(0, 2))+" ")])],1)],1),_c('v-row',[_c('div',{staticClass:"mx-auto title",class:{ 'white--text': _vm.isWebView },domProps:{"innerHTML":_vm._f("htmlDisplayName")(_vm.user.displayName)}})]),_c('v-row',[_c('div',{staticClass:"mx-auto subtitle-1 mb-1",class:{
                            'grey--text': _vm.isWebView,
                            'text--secondary': !_vm.isWebView,
                        }},[_vm._v(" "+_vm._s(_vm.user.email.substr(0, 24))+" ")])]),_c('v-row',{staticClass:"mt-4 pb-3"},[_c('v-btn',{staticClass:"ml-1",attrs:{"small":"","color":"primary","to":{ name: _vm.$constants.ROUTE_NAMES.USER_PROFILE }}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(_vm._s(_vm.settingsIcon))]),_vm._v(" Settings ")],1),_c('v-spacer'),_c('v-btn',{staticClass:"mr-1",attrs:{"small":"","color":"secondary","disabled":_vm.isLoggingOut},on:{"click":_vm.logout}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(_vm._s(_vm.logoutIcon))]),_vm._v(" Logout ")],1)],1)],1):_c('v-sheet',{staticClass:"d-flex align-center justify-center",class:{
                    'pt-7 px-2': _vm.isWebView,
                    'bg-transparent pa-2': !_vm.isWebView,
                },attrs:{"color":_vm.isWebView ? '#1e1e1e' : '',"height":_vm.systemBarHeight + (_vm.isWebView ? 16 : 0)}},[_c('v-btn',{attrs:{"block":"","large":!_vm.isWebView,"color":"primary","to":{ name: _vm.$constants.ROUTE_NAMES.AUTH_SIGN_IN }},on:{"click":_vm.onAuthBtnClicked}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(_vm.signInIcon))]),_vm._v(" Sign In or Sign Up ")],1)],1)]},proxy:true}],null,false,2471292342),model:{value:(_vm.drawerOpen),callback:function ($$v) {_vm.drawerOpen=$$v},expression:"drawerOpen"}},[_c('v-list',{attrs:{"shaped":""}},[_c('v-list-item-group',{staticClass:"primary-navigation",attrs:{"color":"primary"}},_vm._l((_vm.primaryMenuItems),function(item){return _c('v-list-item',{key:item.name,class:{
                        'v-list-item--active':
                            item.routeNames.indexOf(_vm.$route.name) !== -1,
                    },attrs:{"link":"","exact":"","to":item.route},on:{"click":function($event){return _vm.setNavRoute(item.route.name)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[(item.name !== 'mtn-to-orange')?_c('span',[_vm._v(" "+_vm._s(item.name)+" ")]):_c('span',[_vm._v(" MTN "),_c('v-icon',[_vm._v(_vm._s(_vm.exchangeIcon))]),_vm._v(" Orange ")],1)])],1)],1)}),1),(_vm.userIsAdmin)?_c('v-list-group',{attrs:{"prepend-icon":_vm.adminIcon,"value":_vm.$route.path.search('/admin/') !== -1},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_vm._v("Admin")])]},proxy:true}],null,false,3509839752)},[_vm._l((_vm.adminMenuItems),function(menuItem){return _c('v-list-item',{key:menuItem.name,class:{
                        'v-list-item--active':
                            menuItem.routeNames.indexOf(_vm.$route.name) !== -1,
                    },attrs:{"link":"","to":menuItem.route,"exact":""},on:{"click":function($event){return _vm.setNavRoute(menuItem.route.name)}}},[_c('v-list-item-title',[_vm._v(_vm._s(menuItem.name))]),_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(menuItem.icon))])],1)],1)}),(_vm.userIsArnold)?_c('div',_vm._l((_vm.kitchenSinkItems),function(menuItem){return _c('v-list-item',{key:menuItem.name,class:{
                            'v-list-item--active':
                                menuItem.routeNames.indexOf(_vm.$route.name) !==
                                -1,
                        },attrs:{"link":"","to":menuItem.route,"exact":""}},[_c('v-list-item-title',[_vm._v(_vm._s(menuItem.name))]),_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(menuItem.icon))])],1)],1)}),1):_vm._e()],2):_vm._e()],1),_c('div',{staticClass:"pa-2 text-center d-block text--secondary"},[_c('small',[_vm._v("Version: "+_vm._s(_vm.version))])]),(_vm.canInstallApp)?_c('v-card',{staticClass:"mt-4",attrs:{"tile":"","elevation":"0","color":"info"}},[_c('v-card-text',{staticClass:"pb-0"},[_c('p',{staticClass:"subtitle-1 text--white"},[_vm._v(" Get our free app. It won't take up any space on your phone! ")])]),_c('v-card-actions',[_c('install-button')],1)],1):_c('div',{staticClass:"text-center"},[_c('v-btn',{staticClass:"text-none white--text",attrs:{"small":"","color":"#1DA1F2","rounded":"","href":"https://twitter.com/intent/follow?screen_name=nyangapay"}},[_c('v-icon',[_vm._v(_vm._s(_vm.twitterIcon))]),_c('span',[_vm._v("Follow @NyangaPay")])],1)],1)],1):_vm._e(),_c('v-main',{class:_vm.backgroundClass},[(_vm.$route.name !== _vm.$constants.ROUTE_NAMES.HOME)?_c('status-alert'):_vm._e(),(!_vm.loading)?_c('router-view',{staticClass:"mb-16"}):_vm._e(),_c('ul',{staticClass:"secondary-navigation py-3 mt-10",class:{ 'grey lighten-2': !_vm.$vuetify.theme.dark },staticStyle:{"z-index":"2"}},_vm._l((_vm.secondaryMenuItems),function(menuItem){return _c('li',{key:menuItem.name,staticClass:"secondary-navigation__item px-2"},[_c('router-link',{attrs:{"exact":"","to":menuItem.route}},[_vm._v(" "+_vm._s(menuItem.name)+" ")])],1)}),0)],1),_c('notification-message')],1)}
var staticRenderFns = []

export { render, staticRenderFns }