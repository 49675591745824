export default function (displayName: string | null) {
    if (!displayName) {
        return null;
    }
    return displayName
        ?.substr(0, 22)
        .split("+")
        .join(" ")
        .split("🇨🇲")
        .join('<span class="flag-icon flag-icon-cm"></span>')
        .split("🇳🇬")
        .join('<span class="flag-icon flag-icon-ng"></span>');
}
