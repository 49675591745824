import firebase from "firebase/app";
import store from "@/store";

export type User = firebase.UserInfo;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function fetchIdToken(user: any): Promise<string | null> {
    if (typeof user?.getIdToken === "function") {
        return user.getIdToken();
    }
    return new Promise<string | null>((resolve) => {
        resolve(null);
    });
}

export function fetchFromLocalStorage(): User | null {
    if (localStorage.getItem("user")) {
        return JSON.parse(localStorage.getItem("user") || "null");
    }
    return null;
}

export const getCurrentUser = (): Promise<User | null> => {
    return new Promise((resolve, reject) => {
        const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
            unsubscribe();
            store.dispatch("setUser", user).finally(() => {
                resolve(user);
            });
        }, reject);
    });
};

export function userNameFromId(id: string): string {
    switch (id) {
        case "XK5sTR7CRiWlgfAHJi7sQetV5zF3":
            return "Boris";
        case "PumEx4QZ2XdRhCWeaktNaHltV2G3":
            return "Arnold";
        default:
            return id;
    }
}

export function userIsAdmin(): boolean {
    const user = fetchFromLocalStorage();
    if (!user) {
        return false;
    }
    return (
        [
            "XK5sTR7CRiWlgfAHJi7sQetV5zF3",
            "PumEx4QZ2XdRhCWeaktNaHltV2G3",
        ].indexOf(user.uid) != -1
    );
}
