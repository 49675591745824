interface IdentifyParams {
    email: string;
    name: string;
}

interface Segment {
    identify(id: string, params: IdentifyParams): void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    track(eventId: string, params: any): void;
}

export const getSegment = (): Segment => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (window as any).analytics as Segment;
};
